import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Notification from "../components/notification";

export default function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`software`, "peterborough", "cambridge", "uk"]}
        title="Welcome"
      />
      <Notification />
    </Layout>
  );
}
